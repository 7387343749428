
import { computed, defineComponent, ref, onMounted, h, watch } from 'vue';
import { useStore } from 'vuex';
import { APIDataProps, AutoTableCellProps, OptionProps } from '@/types';
import { SelfProps } from '@/store';
import deepClone from '@/utils/deepClone';
import { filterCombinability } from '@/API/program';
import { ColumnsProps, ProgramCodeProps, CategoryProps, GroupTableProps } from '../types';
import { getColumsCS } from './utils';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  components: {
    ExclamationCircleOutlined
  },
  setup() {
    const store = useStore();
    // offer left
    const selfLeftData = computed(() => {
      return store.state.pgmCombinability.offerOther.internal;
    });
    // offer Midd
    const leftData = ref<CategoryProps[]>([]);
    // offer right
    const middData = ref<ProgramCodeProps[]>([]);
    // offer
    const offerData = ref<SelfProps[]>([]);

    const combinabilityResetKey = computed(() => store.state.combinabilityResetKey);
    watch(combinabilityResetKey, (value: string) => {
      leftData.value = [];
      middData.value = [];
      offerData.value = [];
    });

    const isFlag = ref<boolean>(false);

    const isActiveLeft = ref<string>();
    const isActive = ref<string>();
    const isActiveRight = ref<string>();
    const handleActive = (id: string, direction: string, offerType: SelfProps, offerTable: APIDataProps) => {
      const objArr = deepClone(store.state.pgmCombinability);
      if (direction === 'left') {
        otherProgramCollapsedStatus = {};
        isActiveLeft.value = `${offerType.offerTypeKey}-${offerTable.tableId}-${id}`;
        objArr.target = {
          entry: 'OfferOtherProgramOffer',
          internalOffer: {
            offerTypeKey: offerType.offerTypeKey,
            tableId: offerTable.tableId,
            rowId: id
          }
        };
      }
      if (direction === 'midd') {
        isActive.value = id;
        objArr.target = {
          entry: 'OfferOtherProgramCategory',
          otherCategory: id
        };
      }
      if (direction === 'right') {
        isActiveRight.value = id;
        objArr.target = {
          entry: 'OfferOtherProgramCategoryProgram',
          otherProgram: id
        };
      }
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        leftData.value = store.state.pgmCombinability.offerOther.other.category.map((item: any) => {
          item.collapsed = otherProgramCollapsedStatus[`otherProgram-${item.groupName}`] || false;
          return item;
        });
        middData.value = store.state.pgmCombinability.offerOther.other.program;
        offerData.value = store.state.pgmCombinability.offerOther.other.offer;
        if (offerData.value.length > 0) getColumsCS(offerData.value as SelfProps[]);
      });
    };
    // 点击右侧的勾选状态， true/false
    const handleChangeCom = (row: AutoTableCellProps, offerType: string, tableId: string, value: any) => {
      if (!value.isEdit) return;
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: 'OfferOtherProgramCategoryProgramOfferChecked',
        otherOffer: {
          offerTypeKey: offerType,
          tableId: tableId,
          rowId: row.id
        }
      };
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        leftData.value = store.state.pgmCombinability.offerOther.other.category.map((item: any) => {
          item.collapsed = otherProgramCollapsedStatus[`otherProgram-${item.groupName}`] || false;
          return item;
        });
        middData.value = store.state.pgmCombinability.offerOther.other.program;
        offerData.value = store.state.pgmCombinability.offerOther.other.offer;
        if (offerData.value.length > 0) getColumsCS(offerData.value as SelfProps[]);
      });
    };

    let otherProgramCollapsedStatus = {};
    const handleCollapsedClick = (item: any) => {
      item.collapsed = !item.collapsed;
      otherProgramCollapsedStatus[`otherProgram-${item.groupName}`] = item.collapsed;
    };
    // 切换分类信息，如果能找到一个是isVisible:true，那么groupName就显示
    const handleChangeCate = () => {
      leftData.value.forEach((item) => {
        item.isVisible = item.offerType.some((val) => val.isVisible);
      });
    };
    // 点击左侧，中间的勾选状态，有三种状态，0:叉, 1:勾, 2:-
    const handleChangeMidd = (row: OptionProps, direction: string) => {
      const objArr = deepClone(store.state.pgmCombinability);
      if (direction === 'midd') {
        isActive.value = row.id;
        objArr.target = {
          entry: 'OfferOtherProgramCategoryChecked',
          otherCategory: row.id
        };
      }
      if (direction === 'right') {
        isActive.value = row.id;
        objArr.target = {
          entry: 'OfferOtherProgramCategoryProgramChecked',
          otherProgram: row.id
        };
      }
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        leftData.value = store.state.pgmCombinability.offerOther.other.category;
        middData.value = store.state.pgmCombinability.offerOther.other.program;
        offerData.value = store.state.pgmCombinability.offerOther.other.offer;
      });
    };

    const offerOperationType = (value: any) => {
      if (!value.isEdit) {
        return 'icon-X color-g icon-disabled';
      }
      return value.val === 'true' ? 'icon-icon-test color-blue' : 'icon-X color-r';
    };
    return {
      isFlag,
      leftData,
      middData,
      selfLeftData,
      offerData,
      isActive,
      isActiveRight,
      handleActive,
      handleChangeCom,
      handleChangeMidd,
      isActiveLeft,
      handleChangeCate,
      offerOperationType,
      handleCollapsedClick
    };
  }
});
