
import {
  computed,
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRefs,
  nextTick,
  watch,
} from "vue";
import moment from "moment";

import { useStore } from "vuex";
export default defineComponent({
  components: {},
  props: {},
  setup() {
    const store = useStore();
    const specialUsage = computed(
      () => store.state.pgmCombinability.specialUsage
    );
    const faCrossTerm = computed(
      () => store.state.pgmCombinability.faCrossTerm
    );
    const mainWidth = ref<number>(0);
    const init = () => {
      try {
        const resizeHandler = (): void => {
          const clientWidth: number = document.body.clientWidth;
          const sideNavWidth: number = (
            document.querySelector(".side-nav-left") as HTMLDivElement
          ).offsetWidth;
          const W = clientWidth - sideNavWidth;
          mainWidth.value = W;
        };
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
      } catch (e) {
        console.log(e);
      }
    };
    const handlerPeriodChange = (date: string[]): void => {
      faCrossTerm.value.from = date[0];
      faCrossTerm.value.to = date[1];
    };
    onMounted(() => {
      init();
    });
    watch(
      () => store.state.sideNavCollapsed,
      (value) => {
        nextTick(() => {
          init();
        });
      }
    );
    watch(
      specialUsage,
      (newVal) => {
        store.commit("updatePgmCombinabilityspecialUsage", newVal);
      },
      {
        deep: true,
      }
    );
    watch(
      faCrossTerm,
      (newVal) => {
        store.commit("updatePgmCombinabilityfaCrossTerm", newVal);
      },
      {
        deep: true,
      }
    );

    return {
      specialUsage,
      faCrossTerm,
      mainWidth,
      handlerPeriodChange,
    };
  },
});
