
import { computed, defineComponent, ref, onMounted, h } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { APIDataProps } from '@/types';
import { SelfProps } from '@/store';
import deepClone from '@/utils/deepClone';
import { filterCombinability } from '@/API/program';
import { getColumsCS } from './utils';
interface ColumnsProps {
  title?: string;
  dataIndex: string;
  customRender?: Function;
  slots?: any;
}
interface ProgramCodeProps {
  id: string;
  display: string;
  selected: number;
}

interface CategoryProps {
  groupName: string;
  isVisible: boolean;
  offerType: {
    id: string;
    display: string;
    isVisible: boolean;
    selected: number;
  }[];
}

export default defineComponent({
  components: {
    ExclamationCircleOutlined
  },
  setup() {
    const store = useStore();
    const otherProgramCollapsedStatus = {};
    const leftData = computed(() =>
      store.state.pgmCombinability.other.category.map((item: any) => {
        item.collapsed = otherProgramCollapsedStatus[`otherProgram-${item.groupName}`] || false;
        return item;
      })
    );
    const handleCollapsedClick = (item: any) => {
      item.collapsed = !item.collapsed;
      otherProgramCollapsedStatus[`otherProgram-${item.groupName}`] = item.collapsed;
    };
    const middData = computed(() => store.state.pgmCombinability.other.program);
    const rightData = computed(() => {
      if (store.state.pgmCombinability.other.offer.length > 0) {
        const objArr = deepClone(store.state.pgmCombinability.other.offer);
        getColumsCS(objArr);
        return objArr;
      }
      return [];
    });

    const isActive = ref<string>();
    const isActiveRight = ref<string>();
    const isFlag = ref<boolean>(false);

    // const init = () => {
    //   getColumsCS(rightData.value);
    // };

    // 点击右侧的勾选状态， true/false
    const handleChangeCom = (row: any, offerType: string, tableId: string, value: any) => {
      if (!value.isEdit) return;
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: 'OtherProgramCategoryProgramOfferChecked',
        otherOffer: {
          offerTypeKey: offerType,
          tableId: tableId,
          rowId: row.id
        }
      };
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        // init();
      });
    };
    // 点击左侧的勾
    const handleChangeLeft = (row: any) => {
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: 'OtherProgramCategoryChecked',
        otherCategory: row.id
      };
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        // init();
      });
    };
    // 点击左侧，中间的勾选状态，有三种状态，0:叉, 1:勾, 2:-
    const handleChangeMidd = (row: any) => {
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: 'OtherProgramCategoryProgramChecked',
        otherProgram: row.id
      };
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        // init();
      });
    };
    // 切换分类信息，如果能找到一个是isVisible:true，那么groupName就显示
    const handleChangeCate = () => {
      leftData.value.forEach((item: any) => {
        item.isVisible = item.offerType.some((val: any) => val.isVisible);
      });
    };

    // 点击左侧category和中间的program code
    const handleActive = (id: string, direction: string) => {
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: 'OtherProgramCategory',
        otherCategory: id
      };
      if (direction === 'left') {
        isActive.value = id;
      }
      if (direction === 'right') {
        isActiveRight.value = id;
        objArr.target = {
          entry: 'OtherProgramCategoryProgram',
          otherProgram: id
        };
      }
      // 兼容性
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        // init();
      });
    };
    // onMounted(() => {
    //   init();
    // });

    const offerOperationType = (value: any) => {
      if (!value.isEdit) {
        return 'icon-X color-g icon-disabled';
      }
      return value.val === 'true' ? 'icon-icon-test color-blue' : 'icon-X color-r';
    };
    return {
      isFlag,
      leftData,
      middData,
      rightData,
      isActive,
      isActiveRight,
      handleActive,
      handleChangeCom,
      handleChangeMidd,
      handleChangeCate,
      handleChangeLeft,
      offerOperationType,
      handleCollapsedClick
    };
  }
});
