
import { defineComponent, ref, onMounted, computed, nextTick } from 'vue';
import Internal from './Internal.vue';
import OtherProgram from './OtherProgram.vue';
import OfferOtherProgram from './OfferOtherProgram.vue';
import { loadCombinability } from '@/API/program';
import Footer from './components/Footer.vue';
import useNavBar from '@/hooks/Program/useNavBar';
import { useStore } from 'vuex';
import useEventbus from '@/hooks/claim/useEventbus';
export default defineComponent({
  components: {
    Internal,
    OtherProgram,
    OfferOtherProgram,
    Footer
  },
  setup() {
    const store = useStore();

    const minHeight = ref<number>(0);
    const init = () => {
      store.commit('updatePgmCombinability', {
        programId: '',
        internal: {
          left: [],
          right: []
        },
        other: {
          category: [],
          program: [],
          offer: []
        },
        offerOther: {},
        target: {},
        specialUsage: [],
        faCrossTerm: {
          checked: false
        }
      });
      loadCombinability({
        params: { programId: store.state.pgmData.programId }
      }).then((res) => {
        store.commit('updateSpinning', true);
        store.commit('updatePgmCombinability', res);
        setTimeout(() => store.commit('updateSpinning', false), 2000);
      });
    };
    onMounted(() => {
      const clientHeight: number = document.body.clientHeight;
      minHeight.value = clientHeight - 300;
      init();
    });

    return {
      minHeight
    };
  }
});
