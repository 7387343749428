import { SelfProps } from '@/store'
import { GroupTableProps } from '../types'
import { AutoTableColProps } from '@/types'
export const getColumsCS = (data: any) => {
    data.forEach((item: SelfProps) => {
        item.table.forEach((items: GroupTableProps) => {
            items.columns = []
            items.tableCol.forEach((val: AutoTableColProps) => {
                const params = {
                    title: val.display,
                    dataIndex: val.id,
                    slots: { customRender: val.id },
                }
                items.columns.push(params)
            })
        })
    } )
}