
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { APIDataProps } from '@/types';
import { SelfProps } from '@/store';
import { filterCombinability } from '@/API/program';
import deepClone from '@/utils/deepClone';
import { getColumsCS } from './utils';
export default defineComponent({
  setup() {
    const store = useStore();

    const leftData = computed(() => {
      return store.state.pgmCombinability.internal.left;
    });
    const rightData = ref<APIDataProps[]>([]);

    const combinabilityResetKey = computed(() => store.state.combinabilityResetKey);
    watch(combinabilityResetKey, (value: string) => {
      rightData.value = [];
    });

    const isFlag = ref<boolean>(false);
    const isActiveLeft = ref<string>();
    const handleActive = (id: string, offerType: SelfProps, offerTable: APIDataProps) => {
      isActiveLeft.value = `${offerType.offerTypeKey}-${offerTable.tableId}-${id}`;
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: 'OfferInternalOffer',
        internalOffer: {
          offerTypeKey: offerType.offerTypeKey,
          rowId: id,
          tableId: offerTable.tableId
        }
      };
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        rightData.value = store.state.pgmCombinability.internal.right;
        getColumsCS(rightData.value);
      });
    };
    const handleChangeCom = (row: any, offerType: string, tableId: string) => {
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: 'OfferInternalOfferChecked',
        internalOffer: {
          offerTypeKey: offerType,
          tableId: tableId,
          rowId: row.id
        }
      };
      filterCombinability(objArr).then((res) => {
        store.commit('updatePgmCombinability', res);
        rightData.value = store.state.pgmCombinability.internal.right;
        getColumsCS(rightData.value);
      });
    };

    return {
      isFlag,
      leftData,
      rightData,
      handleChangeCom,
      isActiveLeft,
      handleActive
    };
  }
});
